.sidenav {
  height: 100vh;
}

.sidenavleft {
  height: 100vh;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  white-space: nowrap;

  transition: 1s;
}
.sidenavleft svg {
  transition: 0.5s linear;
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  animation: topdown 0s infinite;
}
@keyframes topdown {
  0% {
    height: 400px;
  }
  50% {
    height: 900px;
  }
  100% {
    height: 400px;
  }
}

#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 123;
  opacity: 0.5;
}
.darkLight {
  position: absolute;
  z-index: 123;
}
.darkLight .material-icons {
  font-size: 45px;
  padding: 25px;
  cursor: pointer;

  transition: 0.3s linear;
}
.sidenavright {
  height: 100vh;
  width: 50%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  display: inline;
  overflow-x: hidden;
  transition: 1s;
  white-space: nowrap;
}
.aboutImg {
  width: 30%;
}
#sidenavleftContent {
  transition: 3s;
  display: block;
  position: absolute;
  padding-top: 25px;
}
#sidenavleftContent .bgImgLeft {
  width: 70%;
}
#homeDetImageshow img {
  width: 35%;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-left: 10px solid rgb(22, 22, 22);
  border-right: 10px solid rgb(22, 22, 22);
}
.sidenavleft p:nth-child(2) {
  transition: 0.1s;
  font-size: 30px;
  font-weight: bolder;
}
.sidenavleft #homeDetshow p:nth-child(1) {
  transition: 0.1s;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 0.2em;
}
.sidenavleft #homeDetshow p:nth-child(2) {
  transition: 0.1s;
  font-size: 20px;
  font-weight: bolder;
}

.btnopenNav,
.professLink {
  display: none;
}

.btnclose {
  border: none;
  font-size: 30px;
  float: right;
  margin-right: 20px;
  background-color: transparent !important;
  font-weight: bolder;
}
.btnbackNav {
  display: none;
}
#sidenavrightContent .menuContent {
  height: 20vh;
  color: white;
  font-weight: 30px;
}

#sidenavrightContent .menuContent img {
  width: 100%;
  position: absolute;
  z-index: -12;
  height: 20vh;
}

#sidenavrightContent .menuContent {
  overflow: hidden;
  margin: 0 auto;

  cursor: pointer;
}
#sidenavrightContent .menuContent:hover p {
  transform: scale(1.2);
  transition: 0.5s all ease-in-out;
  overflow: hidden;
}

#sidenavrightContent .menuContent p {
  transition: 0.5s all ease-in-out;
  font-size: 35px;
  text-align: center;
  padding-top: 35px;
  font-weight: bolder;
  letter-spacing: 0.2em;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
#contactLink {
  margin-top: 30px;
}
#contactLink nav a {
  margin: 40px 30px 30px;
}

#downArrow .arrow {
  margin-top: 5%;
}

#downArrow .arrow img {
  width: 50px;
  opacity: 0;
  animation: arrowmove 1.2s infinite;
}
@keyframes arrowmove {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
#downArrow {
  position: fixed;
  z-index: 123;
  bottom: 0;
  right: 23%;
  margin: 10px;
}
#downArrow .arrow .arrow1 {
  animation-delay: 0.1s;
}

#downArrow .arrow .arrow2 {
  position: relative;
  top: -10;
  animation-delay: 0.4s;
}

#downArrow .arrow .arrow3 {
  position: relative;
  top: -9;

  animation-delay: 0.9s;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }
}

.bgImg {
  position: absolute;
  z-index: -123;

  width: 100%;
}

.bgImg img {
  width: 100%;
  height: 100vh;
}

#Achivement,
#Conference,
#Professional,
#Project,
#Training,
#about,
#contact,
#education,
#experience,
#publication,
#researcharea,
#skill,
#teachingexperience {
  -webkit-animation-name: animatebottom;
  -webkit-animation-duration: 1s;
  animation-name: animatebottom;
  animation-duration: 1s;
}
@-webkit-keyframes animatebottom {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes animatebottom {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.firsta {
  height: 100vh;
  overflow: hidden;
  color: white;
}

.firsta .bgImg {
  position: absolute;
  z-index: -123;

  width: 100%;
  left: 0;
}

.menuContent .bgImg1 img {
  width: 100%;
  height: 20vh !important;
}

.seconda {
  padding-top: 50px;
  overflow-y: auto;
  height: 100vh;
  padding-bottom: 5%;
}
#about .seconda p:nth-child(3) {
  padding-top: 30px;
  font-weight: bolder;
  font-size: 30px;
  text-align: center;
}
#about .seconda p:nth-child(4) {
  font-size: 20px;
  text-align: justify;
  padding-left: 15%;
  padding-right: 15%;
}

.firsta h1 {
  font-weight: bolder;
  text-align: center;
  letter-spacing: 0.3em;
  padding-top: 55%;
}
#skill #skilldata {
  border: 1px solid grey;

  padding: 10px;
  border-radius: 10px;
  background-image: linear-gradient(to top, #a3bded 0%, #6991c7 100%);

  background-repeat: no-repeat;
}
#skill p {
  font-size: 15px;
  color: rgb(12, 33, 58);
  font-weight: bolder;
}
.carreo {
  margin-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 30px;
}

.carreo h4 {
  font-weight: bolder;
}

.ed,
.ex,
.rA {
  height: auto;
  border-bottom: 1px solid white;
  border-radius: 26px;
  background: linear-gradient(145deg, #e1e1e1, #dcdada);

  padding: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.exImg img {
  width: 80%;
}
.rA .collapse {
  width: 100%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7e7e7e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(27, 27, 27);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 4, 4);
}
.btnopenNav {
  position: absolute;
  z-index: 1000;
  right: 0;
  margin: 20px;
  outline: none;
  background-color: transparent;
  border: none;
}
.btnbackNav {
  position: absolute;
  margin: 20px;
  outline: none;
  background-color: transparent;
  border: none;
}
.btn-resclose {
  display: none;
}

.achivsecid .btn-openachiv {
  width: 80%;
  padding: 20px;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
  color: white;
  font-weight: bolder;
  background-color: #0c213a;
  font-size: 30px;
}

#Professional .ProfessionalMemberbox {
  padding: 10px;
  width: 90%;

  margin: 20px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: -9px -9px 17px #e8e8e8, 9px 9px 17px #ffffff;
}

#Professional .ProfessionalMemberbox h2 {
  font-size: 20px;
  font-weight: bolder;
}
#Professional .ProfessionalMemberbox p {
  font-size: 18px;
}
#Professional .ProfessionalMemberbox h4 {
  font-size: 13px;
}
.btn-contactme {
  margin-top: 30px;
  outline: none !important;
  font-weight: bolder;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 30;
  width: 100%;
}

.form__label__error {
  float: right;
  color: red;
  font-size: 10px;
  display: none;
  font-weight: bold;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}
.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 10px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
}
.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}
.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  font-weight: 700;
}
.btn-enter {
  outline: none;
  width: 20%;
  background-color: rgb(12, 33, 58);
  color: white;
  border: none;
  padding: 8px;
  margin: 20px;
}
.capbox-inner {
  font: bold 13px arial, sans-serif;
  color: #000000;
}

#CaptchaDiv {
  font: bold 17px verdana, arial, sans-serif;
  font-style: italic;
  color: #000000;
  background-color: #ffffff;
  padding: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

#CaptchaInput {
  margin: 1px 0;
  width: 135px;
}
.contactEmail {
  display: inline-block;
  background-color: rgb(218, 216, 216);
  text-align: center;
  padding: 20px;
  width: 40%;
  margin-bottom: 50px;
  color: black;
  border-radius: 20px;
  font-weight: bolder;
}
.selfcontactbox {
  padding-left: 50px;
}
.selfcontactbox .contactEmail:nth-child(2) {
  float: right;
  margin-right: 50px;
}

.seconfooter {
  font-weight: bolder;
  position: absolute;
  bottom: 0;
  
 padding-left: 40%;
}
#content_loading img{
  width: 25%;
}
#content_show{
  display:none
}
@media (max-width: 500px) {
  #sidenavleftContent .bgImgLeft {
    width: 100%;
  }
  .seconfooter {
    
    padding-left: 30%;
   
  }
  .contactEmail {
    display: inline-block;
    background-color: rgb(218, 216, 216);
    text-align: center;
    padding: 20px;
    width: 100%;
    margin-bottom: 40px;
    color: black;
    border-radius: 20px;
    font-weight: bolder;
  }
  .selfcontactbox {
    padding-left: 70px;
    padding-right: 70px;
  }
  .selfcontactbox .contactEmail:nth-child(2) {
    float: none;
    margin-right: 50px;
  }
  .sidenavright {
    width: 0%;
  }
  .sidenavleft {
    width: 100%;
    white-space: normal;
  }
  #sidenavleftContent {
    padding-top: 20%;
  }
  .glyphicon-align-justify,
  .glyphicon-remove {
    color: white;
    font-size: 30px;
  }
  .bgImg1 {
    display: none;
  }
  .firsta {
    height: auto;
    padding-bottom: 20px;
  }
  #sidenavrightContent {
    margin-top: 50px;
    padding-bottom:60%
  
  }
  #sidenavrightContent .menuContent {
    height: auto;
    margin-top: 30px;
    
  }
  #sidenavrightContent .menuContent p {
    font-size: 15px;
    white-space: nowrap;
  }
  .btn-resclose {
    display: block;
  }
  .btnbackNav,
  .btnopenNav {
    display: block;
  }
  .seconda {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 90%;
  }
  .btnclose {
    display: none;
  }
  .firsta h1 {
    padding-top: 60px;
  }
  #about .seconda p:nth-child(3) {
    padding-top: 30px;
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
  }
  #about .seconda p:nth-child(4) {
    font-size: 20px;
    text-align: justify;
    padding-left: 2%;
    padding-right: 2%;
  }
  .carreo {
    margin-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 30px;
  }
  .aboutImg {
    width: 40%;
  }
  .ed,
  .ex,
  .rA {
    height: auto;

    border-radius: 26px;
    background: #ffffff;
    box-shadow: -5px -5px 10px #666666, 5px 5px 10px #ffffff;
    padding: 20px;
    margin-left: 0;
    margin-right: 0;
    color: black;
  }
  .exImg img {
    width: 50%;
  }
  #downArrow {
    display: none;
  }
  #contactLink {
    margin: 50px 0 20px;
  }
  #contactLink nav a {
    margin: 50px 20px 0;
  }
}
@media (min-width: 500px) and (max-width: 768px) {
  .selfcontactbox {
    padding-left: 70px;
  }
  .selfcontactbox .contactEmail:nth-child(2) {
    float: none;
    margin-right: 50px;
  }
  .sidenavright {
    width: 0%;
  }
  .sidenavleft {
    width: 100%;
  }
  #sidenavleftContent {
    padding-top: 35%;
  }
  .glyphicon-align-justify,
  .glyphicon-remove {
    color: white;
    font-size: 30px;
  }
  .bgImg1 {
    display: none;
  }
  .firsta {
    height: auto;
    padding-bottom: 20px;
  }
  #sidenavrightContent {
    margin-top: 50px;
  }
  #sidenavrightContent .menuContent {
    height: auto;
    margin-top: 30px;
  }
  #sidenavrightContent .menuContent p {
    font-size: 15px;
    white-space: nowrap;
  }
  .btn-resclose {
    display: block;
  }
  .btnbackNav,
  .btnopenNav {
    display: block;
  }
  .seconda {
    overflow-y: auto;
    height: 100vh;
    padding-bottom: 90%;
  }
  .btnclose {
    display: none;
  }
  .firsta h1 {
    padding-top: 60px;
  }
  #about .seconda p:nth-child(3) {
    padding-top: 30px;
    font-weight: bolder;
    font-size: 30px;
    text-align: center;
  }
  #about .seconda p:nth-child(4) {
    font-size: 20px;
    text-align: justify;
    padding-left: 2%;
    padding-right: 2%;
  }
  .carreo {
    margin-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 30px;
  }
  .aboutImg {
    width: 40%;
  }
  .ed,
  .ex,
  .rA {
    height: auto;

    border-radius: 26px;
    background: #ffffff;
    box-shadow: -5px -5px 10px #666666, 5px 5px 10px #ffffff;
    padding: 20px;
    margin-left: 0;
    margin-right: 0;
    color: black;
  }
  .exImg img {
    width: 50%;
  }
  #downArrow {
    display: none;
  }
  #contactLink {
    margin: 50px 0 20px;
  }
  #contactLink nav a {
    margin: 50px 20px 0;
  }
}

@media (min-width: 768px) and (max-width: 1100px) {
    #sidenavleftContent {
        padding-top: 55%;
      }  
}