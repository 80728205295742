.skilldiv{
width: 80%;
padding: 10px 50px 0px 50px;
}
.skilldiv label{
    font-size: 15px;
    letter-spacing:0.2em
}
.skilldiv .progress-bar{
background:#a03f3f;

}
.skilldiv .progress{
    height: 12px;
}