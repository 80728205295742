.cbp_tmtimeline {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
  position: relative;
  
}
.cbp_tmtimeline:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #000000;
  left: 30%;
  margin-left: -10px;
  margin-top: 20px;
}
.cbp_tmtimeline li {
  position: relative;
  padding-top: 0;
}
.cbp_tmtimeline li .cbp_tmtime {
  display: block;
  width: 25%;
  margin-top: 55px;
  background: #ffffff;
  box-shadow:  5px 5px 10px #cccccc,
  -5px -5px 10px #ffffff;
  position: absolute;
}
.cbp_tmtimeline li .cbp_tmtime span {
  display: block;
  text-align: center;
  color: #030303;
}
.cbp_tmtimeline li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #000000;
}
.cbp_tmtimeline li .cbp_tmtime span:last-child {
  font-size: 1.2em;
  color: #0a0a0a;

 
  font-weight: bold;
}
.cbp_tmtimeline li .cbp_tmlabel {
  margin: 0 0 15px 36%;
  color: rgb(0, 0, 0);
  padding: 2em;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  position: relative;
  border-radius: 5px;
}
.cbp_tmtimeline li .cbp_tmlabel h2 {
  margin-top: 0;
  padding: 0 0 5px 0;
  font-size: 17;
  font-weight: bolder;
  color: #000000;
  border-bottom: 1px solid rgba(255, 255, 255, 0.205);
}
.cbp_tmtimeline li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #1a1a1a;
}
.projecticon{
  width: 50px !important;
  height: 50px !important;
  background: #a03f3f !important;
  margin: 40px 0 0 -9px !important;
}
.cbp_tmtimeline li .cbp_tmicon {
  width: 80px;
  height: 80px;
  font-family: ecoico;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: rgb(252, 252, 252);
  border-radius: 40px;
  background: #ffffff;
  box-shadow:  7px 7px 8px #cccccc,
  -7px -7px 8px #ffffff;
  text-align: center;
  left: 27%;
  top: 0;
  padding: 5px;
  margin: 40px 0 0 -25px;
}
.cbp_tmtimeline li .cbp_tmicon1 {
  width: 50px;
  height: 50px;
  font-family: ecoico;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #fff;
  background: #6cbfee;
  border-radius: 50%;
  border: 10px solid #abd2e9;
  text-align: center;
  left: 26%;
  top: 0;
  padding: 5px;
  margin: 40px 0 0 -25px;
}
@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline li .cbp_tmtime span:last-child {
    font-size: 1.5em;
    
  }
 
}
@media screen and (max-width: 47.2em) {

  .cbp_tmtimeline li .cbp_tmtime {
  
    background: #ffffff;
    box-shadow:  none;
  }
  .cbp_tmtimeline li .cbp_tmicon {
    box-shadow: none;
   margin-top: 10%;
  }
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline li .cbp_tmicon1 {
    display: none;
  }
  .cbp_tmtimeline li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline li .cbp_tmlabel {
    margin: 0 0 30px 0;
    padding: 1em;
    font-weight: 400;
    font-size: 95%;
  }
  .cbp_tmtimeline li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #000000;
    top: -20px;
  }
  .cbp_tmtimeline li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #6cbfee;
  }
  .cbp_tmtimeline li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 5px 0 0;
  }
}
