body{
  overflow-x: hidden;
  margin: 0;
  padding: 0;

  background-color: #ffffff;
  overflow: hidden;
  font-family: Comfortaa;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}
::-webkit-scrollbar {
  width: 10px;
}
a {
  text-decoration: none !important;
}
